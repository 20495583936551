import React, { useState, useEffect } from 'react'
import KeyContact from './KeyContact'
import AddButton from '../FormComponents/AddButton'
import KeyContactForm from './KeyContactForm'
import ProfileApiHandler from '../profileApiHandler'
const KeyContacts = ({ contacts, companyId, isCurrentUser }) => {
  const api = new ProfileApiHandler(companyId)
  const [keyContacts, setKeyContacts] = useState(contacts || [])
  const [newContact, setNewContact] = useState({
    job_title: '',
    email: '',
    phone: '',
    name: '',
  })
  const [doneEditing, setDoneEditing] = useState(false)
  const [isAdding, setIsAdding] = useState(false)

  useEffect(() => {
    const getKeyContacts = async () => {
      const res = await api.getKeyContacts()
      const { contacts } = await res.json()
      setKeyContacts(contacts)
    }
    getKeyContacts()
  }, [doneEditing])

  useEffect(() => {
    if (!keyContacts.length && isCurrentUser) {
      setIsAdding(true)
    }
  }, [])
  const renderedContacts = keyContacts.map(contact => (
    <KeyContact
      key={contact.id}
      contact={contact}
      setDoneEditing={setDoneEditing}
      companyId={companyId}
      isCurrentUser={isCurrentUser}
    />
  ))

  const handleAdd = () => {
    setDoneEditing(false)
    setIsAdding(true)
  }

  return (
    <>
      <div className="key-contacts">
        {renderedContacts}
        {isAdding && (
          <div className="add-contact">
            <KeyContactForm
              closeEdit={() => setIsAdding(false)}
              contact={newContact}
              apiAction={form => api.createKeyContact(form)}
              setContact={setNewContact}
              setDoneEditing={setDoneEditing}
            />
          </div>
        )}
      </div>
      {isCurrentUser && <AddButton onClick={handleAdd} />}
    </>
  )
}

export default KeyContacts
