/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react"
import PropTypes from "prop-types"
import { graphColors } from '../../utils/helpers';
import {
  XYPlot,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  LineSeries,
  VerticalBarSeries,
  MarkSeries,
  RadialChart
} from 'react-vis';

class PieChart extends React.Component {
  state = {
    colors: [],
  }

  generateDataWithLabels() {
    const total = this.props.data.reduce((accum, item) => {
      return accum + item.angle;
    }, 0);
    let colorIndex = 0;

    return this.props.data.map((item, i) => {
      colorIndex = colorIndex + 1;

      if (colorIndex === graphColors.length - 1) {
        colorIndex = 0;
      }

      this.state.colors.push(graphColors[colorIndex]);

      return {
        ...item,
        label: `${parseInt((item.angle / total) * 100)}%`,
        color: graphColors[colorIndex],
      };
    });
  }

  render () {
    return (
      <div className="text-center graph-container mt-5">
        <h5>{this.props.title}</h5>
        <RadialChart
          data={this.generateDataWithLabels()}
          width={200}
          height={200}
          labelsRadiusMultiplier={0.9}
          showLabels
          colorType="literal"
        />

        <div className="container">
          <div className="row">
            {
              this.props.answers.map((answer, i) => {
                return (
                  <div className="col-sm">
                    <div className="contianer">
                      <div className="row">
                        <div className="col-2">
                          <div
                            style={{
                              background: this.state.colors[i],
                              height: '20px',
                              width: '20px',
                            }}
                          ></div>
                        </div>
                        <div className="col-2">{answer}</div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    );
  }
}

export default PieChart
