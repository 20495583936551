/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import 'array-flat-polyfill';
import React from "react"
import PropTypes from "prop-types"
import { getCSRFToken } from '../../utils/helpers';
import AnswerQuestion from './AnswerQuestion';

class AnswerQuestions extends React.Component {
  constructor() {
    super();

    this.state = {
      questions: [],
    }
  }

  componentDidMount() {
    fetch(`/answer_questions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken(document),
        'Accept': 'application/json',
      },
    })
    .then(res => res.json())
    .then(data => {
      this.sortQuestionsByCategory(data.supply_chains)
    })
    .catch((err) => {
      console.log('error: ', err);
    });
  }

  sortQuestionsByCategory(supply_chains) {
    const order = [
      'Company Information',
      'Contact Information',
      'Quality System',
    ];

    const questions = supply_chains.reduce((accum, supplyChain) => {
      accum = [...accum, ...supplyChain.questions];
      return accum;
    }, []).sort((a, b) => {
      if (a.category && b.category) {
        if (a.category.name < b.category.name) {
          return -1;
        }
        if (a.category.name > b.category.name) {
          return 1;
        }
      }

      return 0;
    })

    const questionsWithCategory = questions.filter(
      question => question.category
    );

    const questionsWithOutCategory = questions.filter(
      question => !question.category
    );

    const uniqueQuestions = [...new Set(questionsWithCategory.map(
      question => JSON.stringify(question)
    ))].map(question => JSON.parse(question));

    const questionOrder = order.map(item => {
      return uniqueQuestions.filter(question => {
        return question.category.name === item;
      });
    }).flat();

    const restQuestions = uniqueQuestions.filter(question => {
      return !order.includes(question.category.name);
    });

    this.setState({
      supplyChains: supply_chains,
      questions: [
        ...questionOrder,
        ...restQuestions,
        ...questionsWithOutCategory
      ],
    });
  }

  renderQuestions(answered) {
    const questions = answered
      ? this.state.questions.filter(question => question.answer && (question.answer.text || question.answer.options.length > 0))
      : this.state.questions.filter(question => !question.answer || (!question.answer.text && question.answer.options.length === 0))

    return (
      <div className="row">
        <div className="container font-weight-bold">
          <div className="row col pt-2 pb-2">
            <div className="col-2 border bg-light text-center">Category</div>
            <div className="col-4 border bg-light">Question</div>
            <div className="col-4 border bg-light text-center">Answer</div>
            <div className="col-2 border bg-light text-center">Notes<br/>(Internal only)</div>
          </div>
        </div>
        <div className="questions-container container">
          {
            questions.map(question => {
              return (
                <AnswerQuestion
                  key={question.id}
                  question={question}
                  company={question.company}
                />
              )
            })
          }
        </div>
      </div>
    )
  }

  render () {
    return (
      <React.Fragment>
        <h3>Unanswered Questions</h3>
        {this.renderQuestions(false)}

        <hr />

        <h3>Answered Questions</h3>
        {this.renderQuestions(true)}
      </React.Fragment>
    );
  }
}

export default AnswerQuestions
