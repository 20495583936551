/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react"
import { getCSRFToken } from '../../utils/helpers';

class DatabaseUploadForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: props.data,
      savedCompanies: [],
    }
  }

  handleOnChange(e, company, index) {
    const updatedCompany = { ...company, ...{ [e.target.name]: e.target.value }};

    this.setState({
      companies: this.state.companies.map((item, i) => {
        if (i === index) {
          return updatedCompany;
        }

        return item;
      }),
    });
  }

  handleSubmit(e, companyData, index) {
    e.preventDefault();

    const company = {
      company: {
        name: companyData.company_name,
        created_by: companyData.created_by,
        updated_by: companyData.updated_by,
        naics_code: companyData.naics_code,
        sic_code: companyData.sic_code,
        website: companyData.website,
      },
      address: {
        line_1: companyData.address_line_1,
        line_2: companyData.address_line_2,
        city: companyData.city,
        state: companyData.state,
        postal_code: companyData.postal_code,
        country: companyData.country,
        phone_number_1: companyData.phone_number_1,
        phone_number_1_ext: companyData.phone_number_1_ext,
        phone_number_2: companyData.phone_number_2,
        phone_number_2_ext: companyData.phone_number_2_ext,
        fax_number: companyData.fax_number,
      },
      users: [
        {
          email: companyData.user_1_email,
          first_name: companyData.user_1_first_name,
          last_name: companyData.user_1_last_name,
          admin: companyData.user_1_admin,
          password: companyData.user_1_password,
          password_confirmation: companyData.user_1_password,
        },
        {
          email: companyData.user_2_email,
          first_name: companyData.user_2_first_name,
          last_name: companyData.user_2_last_name,
          admin: companyData.user_2_admin,
          password: companyData.user_2_password,
          password_confirmation: companyData.user_2_password,
        }
      ],
    }
  
    fetch(`/admin/database_uploads`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken(document),
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        admin_database_upload: company,
      }),
    })
    .then(res => res.json())
    .then(response => {
      if (response.error) {
        this.setState({
          companies: this.state.companies.map((company, i) => {
            if (i === index) {
              return {
                ...company, 
                message: {
                  text: response.error,
                  type: 'alert-danger',
                },
              }
            }

            return company;
          }),
        });
      } else {
        this.setState({
          companies: this.state.companies.map((item, i) => {
            if (index === i) {
              return {
                ...item,
                saved: true,
                message: {
                  text: 'Your record has successfully uploaded to the database',
                  type: 'alert-success',
                },
              };
            }
  
            return item;
          }),
        });
      }
    })
    .catch((err) => {
      console.log('error: ', err);
    });
  }

  render () {
    return (
      <div className="row">
        {
          this.state.companies.map((company, i) => {
            return (
              <form onSubmit={(e) => this.handleSubmit(e, company, i)} key={i} role="form" className="col-12 mb-4">
                <div className="row">
                  <div className="col-md-12 order-md-1 card" style={{ backgroundColor: company.saved ? 'rgb(233, 236, 239)' : '' }}>
                    <h4 className="mb-3 mt-4">Company #{i + 1}</h4>
                    <div className="needs-validation">
                      <div className="row">
                        <div className="col-md-3 mb-3">
                          <label>Company Name</label>
                          <input type="text" className="form-control" name="company_name" placeholder="" value={company.company_name || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>Created By</label>
                          <input type="text" className="form-control" name="created_by" placeholder="" value={company.created_by || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>Updated By</label>
                          <input type="text" className="form-control" name="updated_by" value={company.updated_by || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>NAICS Code</label>
                          <input type="text" className="form-control" name="naics_code" value={company.naics_code || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>SIC Code</label>
                          <input type="text" className="form-control" name="sic_code" value={company.sic_code || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>Website</label>
                          <input type="text" className="form-control" name="website" value={company.website || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                      </div>

                      <hr />

                      <div className="row">
                        <h4 className="col-12">Address</h4>
                        <div className="col-md-3 mb-3">
                          <label>Address Line 1</label>
                          <input type="text" className="form-control" name="address_line_1" value={company.address_line_1 || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>Address Line 2</label>
                          <input type="text" className="form-control" name="address_line_2" value={company.address_line_2 || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>City</label>
                          <input type="text" className="form-control" name="city" value={company.city || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>state</label>
                          <input type="text" className="form-control" name="state" value={company.state || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>Postal Code</label>
                          <input type="text" className="form-control" name="postal_code" value={company.postal_code || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>Country</label>
                          <input type="text" className="form-control" name="country" value={company.country || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>Phone Number 1</label>
                          <input type="text" className="form-control" name="phone_number_1" value={company.phone_number_1 || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>Phone Number 1 Ext</label>
                          <input type="text" className="form-control" name="phone_number_1_ext" value={company.phone_number_1_ext || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>Phone Number 2</label>
                          <input type="text" className="form-control" name="phone_number_2" value={company.phone_number_2 || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>Phone Number 2 Ext</label>
                          <input type="text" className="form-control" name="phone_number_2_ext" value={company.phone_number_2_ext || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>Fax Number</label>
                          <input type="text" className="form-control" name="fax_number" value={company.fax_number || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                      </div>

                      <hr />

                      <div className="row">
                        <h4 className="col-12">User #1</h4>
                        <div className="col-md-3 mb-3">
                          <label>Email</label>
                          <input type="text" className="form-control" name="user_1_email" value={company.user_1_email || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>First Name</label>
                          <input type="text" className="form-control" name="user_1_first_name" value={company.user_1_first_name || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>Last Name</label>
                          <input type="text" className="form-control" name="user_1_last_name" value={company.user_1_last_name || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>Password</label>
                          <input type="text" className="form-control" name="user_1_password" value={company.user_1_password || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                      </div>

                      <hr />

                      <div className="row">
                        <h4 className="col-12">User #2</h4>
                        <div className="col-md-3 mb-3">
                          <label>Email</label>
                          <input type="text" className="form-control" name="user_2_email" value={company.user_2_email || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>First Name</label>
                          <input type="text" className="form-control" name="user_2_first_name" value={company.user_2_first_name || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>Last Name</label>
                          <input type="text" className="form-control" name="user_2_last_name" value={company.user_2_last_name || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label>Password</label>
                          <input type="text" className="form-control" name="user_2_password" value={company.user_2_password || ''} onChange={(e) => { this.handleOnChange(e, company, i) }} disabled={company.saved ? true : false} />
                        </div>
                      </div>
                      <hr className="mb-4" />
                      {
                        !company.saved && <button className="btn btn-primary btn-lg btn-block mb-4 col-3" type="submit">Save Company</button>
                      }
                      { 
                        company.message && (
                          <div className={`alert ${company.message.type}`} role="alert">
                            {company.message.text}
                          </div>
                        )
                      }   
                    </div>
                  </div>
                </div>
              </form>
            )
          })
        }
      </div>
    );
  }
}

export default DatabaseUploadForm;
