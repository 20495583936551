/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react"
import TextInput from './input-type/TextInput';
import TextAreaInput from './input-type/TextAreaInput';
import RadioInput from './input-type/RadioInput';
import NumberInput from './input-type/NumberInput';
import DateInput from './input-type/DateInput';
import SelectInput from './input-type/SelectInput';
import CheckBoxInput from './input-type/CheckBoxInput';
import NoteInput from './input-type/NoteInput';

class AnswerQuestion extends React.Component {
  constructor(props) {
    super(props);
  }

  renderInputType() {
    const { input_type } = this.props.question;

    if (input_type === 'radio') {
      return (
        <RadioInput
          question={this.props.question}
          answer={this.props.question.answer}
          company={this.props.company}
        />
      )
    }

    if (input_type === 'textarea') {
      return (
        <TextAreaInput
          question={this.props.question}
          answer={this.props.question.answer}
          company={this.props.company}
        />
      )
    }

    if (input_type === 'number') {
      return (
        <NumberInput
          question={this.props.question}
          answer={this.props.question.answer}
          company={this.props.company}
        />
      )
    }

    if (input_type === 'date') {
      return (
        <DateInput
          question={this.props.question}
          answer={this.props.question.answer}
          company={this.props.company}
        />
      )
    }

    if (input_type === 'select') {
      return (
        <SelectInput
          question={this.props.question}
          answer={this.props.question.answer}
          company={this.props.company}
        />
      )
    }

    if (input_type === 'checkbox') {
      return (
        <CheckBoxInput
          question={this.props.question}
          answer={this.props.question.answer}
          company={this.props.company}
        />
      )
    }

    return (
      <TextInput
        question={this.props.question}
        answer={this.props.question.answer}
        company={this.props.company}
      />
    )
  }

  getBackgroundColor() {
    if (this.state.background) {
      return this.state.background
    }

    if (this.props.index % 2 === 0) {
      return 'bg-light';
    }

    return 'bg-white';
  }

  render () {
    return (
      <div className="row col pt-2 pb-2 border-bottom">
        <div className="col-2">
          {this.props.question.category && this.props.question.category.name}
        </div>
        <div className="col-4">{this.props.question.text}</div>
        <div className="col-4">{this.renderInputType()}</div>
        <div className="col-2">
          <NoteInput
            question={this.props.question}
          />
        </div>
      </div>
    );
  }
}

export default AnswerQuestion
