/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react"
import PropTypes from "prop-types"

class CompanyQuestions extends React.Component {
  constructor(props) {
    super(props);

    const currentCompany = props.companies.find(c => c.id === props.page.resource.company_id);

    this.state = {
      currentCompany: currentCompany || {},
      currentQuestion: currentCompany.questions.find(q => q.id === props.page.resource.question_id) || {},
      text: '',
      options: '',
    };
  }

  handleCompanyOnChange(e) {
    this.setState({
      currentCompany: this.props.companies.filter(company => company.id === parseInt(e.target.value))[0]
    })
  }

  handleQuestionOnChange(e) {
    const currentQuestion = this.state.currentCompany.questions.filter(question => question.id === parseInt(e.target.value))[0]

    this.setState({
      currentQuestion,
    })
  }

  handleTextChange(e) {
    this.setState({
      text: e.target.value,
    })
  }

  handleOptionsChange(e) {
    this.setState({
      options: e.target.value,
    })
  }

  render () {
    return (
      <div className="react-input-field">
        <div className="field-unit field-unit--belongs-to">
          <div className="field-unit__label">
            <label htmlFor="answer_company_id">Company</label>
          </div>
          <div className="field-unit__field">
            <select
              name="answer[company_id]"
              id="answer_company_id"
              value={this.state.currentCompany.id}
              onChange={(e) => this.handleCompanyOnChange(e)}
            >
              <option value=""></option>
              {
                this.props.companies.map(company => {
                  return (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  )
                })
              }
            </select>
          </div>
        </div>
        {
          this.state.currentCompany.name && (
            <div className="field-unit field-unit--belongs-to">
              <div className="field-unit__label">
                <label htmlFor="answer_question_id">Question</label>
              </div>
              <div className="field-unit__field">
                <select
                  name="answer[question_id]"
                  id="answer_question_id"
                  onChange={(e) => this.handleQuestionOnChange(e)}
                  value={this.state.currentQuestion.id}
                >
                  <option value=""></option>
                  {
                    this.state.currentCompany.questions.map(question => {
                      return (
                        <option key={question.id} value={question.id}>
                          {question.text}
                        </option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
          )
        }
        {
          this.state.currentQuestion.id && (
            <div className="field-unit field-unit--belongs-to">
              <div className="field-unit__label">
                <label htmlFor="answer_question_id">Question info</label>
              </div>
                <div className="field-unit__field">
                <ul>
                  <li>Input Type: {this.state.currentQuestion.input_type}</li>
                  <li>Option: {this.state.currentQuestion.options.join(', ')}</li>
                </ul>
              </div>
            </div>
          )
        }
        <div className="field-unit field-unit--string">
          <div className="field-unit__label">
            <label htmlFor="answer_text">Text</label>
          </div>
          <div className="field-unit__field">
            <input
              type="text"
              name="answer[text]"
              id="answer_text"
              onChange={(e) => this.handleTextChange(e)}
              value={this.state.text}
            />
          </div>
        </div>
        <div className="field-unit field-unit--string">
          <div className="field-unit__label">
            <label htmlFor="options_text">Options</label>
          </div>
          <div className="field-unit__field">
            <input
              type="text"
              name="answer[options][]"
              id="options_text"
              onChange={(e) => this.handleOptionsChange(e)}
              value={this.state.options}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyQuestions
