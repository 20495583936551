import React from 'react'

import SectionWrapper from './SectionWrapper'
import CompanyListItems from '../CompanyListItems'

const SystemAndCertsSection = ({ company, isCurrentUser }) => {
  return (
    <SectionWrapper title="Systems and Certifications">
      <CompanyListItems
        items={company.management_system_certs}
        companyId={company.id}
        column="management_system_certs"
        sectionTitle="Management Systems and Certifications"
        isCurrentUser={isCurrentUser}
      />

      <CompanyListItems
        items={company.awards}
        companyId={company.id}
        column="awards"
        sectionTitle="Awards and Recognitions"
        isCurrentUser={isCurrentUser}
      />
    </SectionWrapper>
  )
}

export default SystemAndCertsSection
