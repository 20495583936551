import React from 'react'
import SubmitButton from '../FormComponents/SubmitButton'
import CloseFormButton from '../FormComponents/CloseFormButton'
const KeyContactForm = props => {
  const {
    contact,
    editing,
    closeEdit,
    apiAction,
    setContact,
    setDoneEditing,
  } = props
  const handleInputChange = e => {
    setContact({
      ...contact,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = async e => {
    e.preventDefault()
    return await apiAction(contact, editing ? contact.id : null)
      .then(res => res.json())
      .then(() => {
        closeEdit()
        setDoneEditing(true)
        setContact({
          job_title: '',
          email: '',
          phone: '',
          name: '',
        })
      })
  }
  const inputs = Object.keys(contact).map(key => {
    if (key === 'id' || key === 'company_id') return null
    const normalCasedKey = key.replace('_', ' ')
    const placeholder = normalCasedKey
      .split(' ')
      .map(word => `${word[0].toUpperCase()}${word.slice(1, word.length)}`)
      .join(' ')
    return (
      <input
        className="company-profile__form-input"
        key={key}
        type="text"
        name={key}
        value={contact[key] || ''}
        placeholder={placeholder}
        onChange={handleInputChange}
      />
    )
  })
  return (
    <form
      onSubmit={e => handleSubmit(e)}
      className={`company-profile__form ${!editing ? 'key-contact-card' : ''}`}
    >
      {inputs}
      <CloseFormButton onClick={closeEdit} />
      <SubmitButton />
    </form>
  )
}

export default KeyContactForm
