import React, { useState } from 'react'
import CompanyListItem from './CompanyListItem'
import EditButton from '../FormComponents/EditButton'
import ProfileApiHandler from '../profileApiHandler'
import AddButton from '../FormComponents/AddButton'
import SubmitButton from '../FormComponents/SubmitButton'
import CloseFormButton from '../FormComponents/CloseFormButton'

const CompanyListItems = props => {
  const {
    items,
    companyId,
    column,
    sectionTitle,
    isLink,
    isCurrentUser,
  } = props
  const api = new ProfileApiHandler(companyId)
  //remove empty or null values
  const [listItems, setListItems] = useState(
    items ? items.filter(item => (!item ? false : item)) : []
  )
  const [isEditing, setIsEditing] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [newListItem, setNewListItem] = useState('')

  const updateItem = (index, newValue) => {
    const newList = [
      ...listItems.slice(0, index),
      newValue,
      ...listItems.slice(index + 1),
    ]
    setListItems(newList)
    return newList
  }

  const handleSubmit = async e => {
    e.preventDefault()
    await api.updateCompanyArrayFields({ [column]: listItems })
    setIsEditing(false)
  }

  const handleAddInputSubmit = async (e, newItem) => {
    e.preventDefault()
    await api.updateCompanyArrayFields({ [column]: [...listItems, newItem] })
    setListItems([...listItems, newItem])
    setNewListItem('')
  }

  const handleInputChange = e => {
    setNewListItem(e.target.value)
  }

  const isAddingForm = (
    <li className="company-list__item">
      <form onSubmit={e => handleAddInputSubmit(e, newListItem)}>
        <input
          type="text"
          value={newListItem || ''}
          onChange={handleInputChange}
        />
        <div className="button-group--row">
          <button type="submit" style={{ background: 'transparent' }}>
            <AddButton />
          </button>
          <button
            onClick={() => setIsAdding(false)}
            style={{ background: 'transparent' }}
          >
            x
          </button>
        </div>
      </form>
    </li>
  )

  const renderedItems = (
    <ul className="company-list__items">
      {listItems.map((item, index) => {
        //filter out empty values for deleting
        if (!item) return null
        return (
          <CompanyListItem
            key={`${item}-${index}`}
            item={item}
            index={index}
            isEditing={isEditing}
            isLink={isLink}
            updateItem={updateItem}
            isCurrentUser={isCurrentUser}
            handleDeleteItem={index =>
              api.updateCompanyArrayFields({
                [column]: updateItem(index, ''),
              })
            }
          />
        )
      })}
      {!isEditing && isAdding && isAddingForm}
    </ul>
  )

  const editListItemsForm = (
    <form onSubmit={handleSubmit}>
      {renderedItems}
      <CloseFormButton onClick={() => setIsEditing(false)} />
      <SubmitButton />
    </form>
  )
  return (
    <div className="company-profile__section-content">
      <div className="company-list">
        <div className="company-list__intro">
          <h3>{sectionTitle}</h3>
          {isCurrentUser && (
            <div className="company-list--mod-buttons">
              <EditButton
                disabled={!listItems || !listItems.length}
                onClick={() => setIsEditing(true)}
              />
              <AddButton onClick={() => setIsAdding(true)} />
            </div>
          )}
        </div>
        {isEditing ? editListItemsForm : renderedItems}
      </div>
    </div>
  )
}

export default CompanyListItems
