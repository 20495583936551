/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react"
import PropTypes from "prop-types"
import { publish } from "../utils/pubSub";
import SideBarToggleButton from './SideBarToggleButton';

import {
  Nav, Navbar, NavDropdown, Form,FormControl, Button, Container,
} from 'react-bootstrap'

class NavigationBar extends React.Component {
  render () {
    const { first_name, last_name, email, admin } = this.props.current_user || {};

    return (
      <Container>
        <Navbar fixed="top" bg="light" expand="lg">
          { this.props.signed_in && <Nav.Link><SideBarToggleButton /></Nav.Link> }
          <Navbar.Brand href="/">Source Identity{this.props.company ? ` | ${this.props.company.name}` : ''}</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {
              (this.props.signed_in || admin ) ? (
                <>
                <Nav className="navbar-right ml-auto">
                  <NavDropdown alignRight title={`${first_name} ${last_name}`} id="basic-nav-dropdown">
                    <NavDropdown.Item href={this.props.profile_path}>Profile</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item rel="nofollow" data-method="delete" href="/users/sign_out">Logout</NavDropdown.Item>
                    {
                      this.props.stop_impersonating_path && (
                        <NavDropdown.Item
                          rel="nofollow"
                          data-method="post"
                          href={this.props.stop_impersonating_path}
                        >
                        <div className="btn btn-primary">Go back to Admin</div>
                        </NavDropdown.Item>
                      )
                    }
                  </NavDropdown>
                </Nav>
                </>
              ) : (
                <Nav className="navbar-right ml-auto">
                  <NavDropdown.Item href="/users/sign_in">Login</NavDropdown.Item>
                </Nav>
              )
            }
          </Navbar.Collapse>
        </Navbar>
      </Container>
    );
  }
}

export default NavigationBar
