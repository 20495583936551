import React from 'react'

const EditButton = ({ onClick, disabled }) => {
  return (
    <figure
      style={disabled ? { color: '#bdbdbd', cursor: 'not-allowed' } : {}}
      className={`${disabled ? '--disabled' : ''}`}
      onClick={disabled ? null : onClick}
    >
      <i className="fa fa-pencil" aria-hidden="true"></i>
    </figure>
  )
}

export default EditButton
