/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react"
import PropTypes from "prop-types"
import { publish } from '../../utils/pubSub';
import { getCSRFToken } from '../../utils/helpers';

class VerifyConnectionButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      verified_by_supplier: props.supply_chain.verified_by_supplier,
    }
  }

  handleOnClick(e) {
    e.preventDefault();
    const message = this.props.supply_chain.verified_by_supplier
      ? 'Are you sure you want to remove your connection'
      : 'Are you sure you want to connect';

    if (confirm(message) === true) {
      fetch(`/supply_chains/${this.props.supply_chain.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCSRFToken(document),
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          ...this.props.supply_chain,
          verified_by_supplier: !this.props.supply_chain.verified_by_supplier
        }),
      })
      .then(res => res.json())
      .then(supply_chain => {
        debugger
        this.setState({
          verified_by_supplier: supply_chain.verified_by_supplier,
        });
      })
      .catch((err) => {
        console.log('error: ', err);
      });
    }
  }

  render () {
    const { verified_by_supplier } = this.state;

    return (
      <button
        className="btn btn-primary col-9"
        onClick={(e) => this.handleOnClick(e)}
      >
        { verified_by_supplier ? 'Connected' : 'Verify Connection' }
      </button>
    );
  }
}

export default VerifyConnectionButton;
