/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react"
import PropTypes from "prop-types"
import { graphColors } from '../../utils/helpers';
import {
  XYPlot,
  XAxis,
  YAxis,
  AreaSeries,
  ChartLabel,
  VerticalGridLines,
  HorizontalGridLines,
  tickFormat,
  DiscreteColorLegend,
} from 'react-vis';

class PlotChart extends React.Component {
  getWidth(columns) {
    return parseInt(window.innerWidth * (columns/12)) - 200;
  }

  render () {
    return (
      <div className="text-center graph-container mt-5 pb-5 pl-5 pr-5">
        <h5>{this.props.title}</h5>

        <XYPlot width={this.getWidth(8)} height={250} style={{overflow: 'visible'}}>

          <VerticalGridLines />
          <HorizontalGridLines />

          <XAxis
            attr="x"
            attrAxis="y"
            orientation="bottom"
            tickFormat={function tickFormat(d){return new Date(d).toLocaleDateString()}}
            tickLabelAngle={-60}
          />
          <YAxis />
          <ChartLabel
           text="X Axis"
           className="alt-x-label"
           includeMargin={false}
           xPercent={0.025}
           yPercent={1.01}
           />

          <ChartLabel
           text="Y Axis"
           className="alt-y-label"
           includeMargin={false}
           xPercent={0.06}
           yPercent={0.06}
           style={{
             transform: 'rotate(-90)',
             textAnchor: 'end'
           }}
           />

          <AreaSeries
            data={[
              {
                x: 1513987200000,
                y: 1232
              },
              {
                x: 1513987200000,
                y: 1567
              },
              {
                x: 1514073600000,
                y: 2500
              },
              {
                x: 1514160000000,
                y: 6786
              },
              {
                x: 1514246400000,
                y: 10123
              },
              {
                x: 1514332800000,
                y: 54209
              },
              {
                x: 1514419200000,
                y: 63480
              },
              {
                x: 1514505600000,
                y: 52304
              },
              {
                x: 1514592000000,
                y: 79899
              },
              {
                x: 1514678400000,
                y: 88889
              },
            ]}
            opacity={0.25}
            style={{}}
          />
          {/*<AreaSeries
            data={[
              {
                x: 0,
                y: 10
              },
              {
                x: 1,
                y: 10.411167447129799
              },
              {
                x: 2,
                y: 10.368507079202704
              },
              {
                x: 3,
                y: 10.170592566358025
              },
              {
                x: 4,
                y: 10.629576791984322
              },
              {
                x: 5,
                y: 10.07614417367928
              },
              {
                x: 6,
                y: 9.535445983904346
              },
              {
                x: 7,
                y: 10.035045276155845
              },
              {
                x: 8,
                y: 10.056578898748057
              },
              {
                x: 9,
                y: 9.930778647944452
              },
              {
                x: 10,
                y: 9.84949970854549
              },
              {
                x: 11,
                y: 9.609125868900204
              },
              {
                x: 12,
                y: 8.89291148121234
              },
              {
                x: 13,
                y: 8.325732392880079
              },
              {
                x: 14,
                y: 8.588884430048052
              },
              {
                x: 15,
                y: 8.429243265854215
              },
              {
                x: 16,
                y: 8.947179378023886
              },
              {
                x: 17,
                y: 9.30052199810277
              },
              {
                x: 18,
                y: 8.851168515616648
              },
              {
                x: 19,
                y: 9.171551147786932
              },
              {
                x: 20,
                y: 8.959183209754508
              }
            ]}
            opacity={0.25}
            style={{}}
          />*/}
        </XYPlot>
      </div>
    );
  }
}

export default PlotChart;
