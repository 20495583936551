/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react"
import PropTypes from "prop-types"

import TagsInput from 'react-tagsinput'

import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.

class DynamicInputField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {tags: props.value};
  }

  handleChange(tags) {
    this.setState({tags})
  }

  render() {
    return (
      <div>
        <TagsInput value={this.state.tags} onChange={(tags) => this.handleChange(tags)} />
        {
          this.state.tags.map((tag, i) => {
            return (
              <input
              style={{ display: 'none' }}
                key={`${tag}-${i}`}
                type="text"
                value={tag}
                name={`${this.props.resource_class_name}[${this.props.field.attribute}][]`}
                onChange={() => {}}
              />
            );
          })
        }
      </div>
    );
  }
}

export default DynamicInputField
