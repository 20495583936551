/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react"
import PropTypes from "prop-types"
import { publish, subscribe, unsubscribe } from '../../utils/pubSub';
import { getCSRFToken } from '../../utils/helpers';

class AddQuestion extends React.Component {
  constructor(props) {
    super(props);

    const hasQuestion = props.questionnaire_questions.some(
      question => question.id === props.question.id
    );

    if (hasQuestion) {
      this.state = {
        background: 'si-bg-info',
        buttonColor: 'btn-danger',
        subscribeToken: this.subscribeToken(),
      }
    } else {
      this.state = {
        background: '',
        buttonColor: 'btn-secondary',
        subscribeToken: this.subscribeToken(),
      }
    }

    this.subscribeToken = this.subscribeToken.bind(this);
  }

  subscribeToken() {
    return subscribe('ANSWER_STATUS', (eventName, action) => {
      if (this.props.question.id === action.question.id) {
        switch (action.type) {
          case 'ADD':
            this.setState({
              background: 'si-bg-info',
              buttonColor: 'btn-danger',
            });
            break;
          case 'REMOVE':
            this.setState({
              background: '',
              buttonColor: 'btn-secondary',
            });
            break;
        }
      }
    });
  }

  componentWillUnmount() {
    unsubscribe(this.state.subscribeToken);
  }

  addQuestion(e) {
    e.preventDefault();

    if (this.state.background) {
      fetch(`/questionnaires_questions/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCSRFToken(document),
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          questionnaires_question: {
            question_id: this.props.question.id,
            questionnaire_id: this.props.questionnaire.id,
          }
        }),
      })
      .then(res => res.json())
      .then(questionnairesQuestion => {
        this.setState({
          background: '',
          buttonColor: 'btn-secondary',
        })

        publish('UPDATE_QUESTION_COUNT', { type: 'REMOVE' });
        publish('ANSWER_STATUS', {
          type: 'REMOVE',
          question: questionnairesQuestion.question,
        });
        publish('TOGGLE_QUESTIONS_MODAL', {
          type: 'REMOVE',
          question: questionnairesQuestion.question,
        });
      })
      .catch((err) => {
        console.log('error: ', err);
      });
    } else {
      fetch(`/questionnaires_questions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCSRFToken(document),
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          questionnaires_question: {
            question_id: this.props.question.id,
            questionnaire_id: this.props.questionnaire.id,
          }
        }),
      })
      .then(res => res.json())
      .then(questionnairesQuestion => {
        this.setState({
          background: 'si-bg-info',
          buttonColor: 'btn-danger',
        })

        publish('UPDATE_QUESTION_COUNT', { type: 'ADD' });
        publish('ANSWER_STATUS', {
          type: 'ADD',
          question: questionnairesQuestion.question,
        });
        publish('TOGGLE_QUESTIONS_MODAL', {
          type: 'ADD',
          question: questionnairesQuestion.question,
        });
      })
      .catch((err) => {
        console.log('error: ', err);
      });
    }
  }

  getBackgroundColor() {
    if (this.state.background) {
      return this.state.background
    }

    if (this.props.index % 2 === 0) {
      return 'bg-light';
    }

    return 'bg-white';
  }

  getStatus() {
    if (this.state.background === 'si-bg-info') {
      return 'Remove'
    }

    return 'Ask Suppliers'
  }

  render () {
    return (
      <div className="row col">
        <div className={`col-2 border p-2 ${this.getBackgroundColor()}`}>{`${this.props.question_percent}%`}</div>
        <div className={`col-6 border p-2 ${this.getBackgroundColor()}`}>{this.props.question.text}</div>
        <div className={`col-2 border p-2 ${this.getBackgroundColor()}`}>{this.props.category ? this.props.category.name : ''}</div>
        <div className={`col-2 border p-2 ${this.getBackgroundColor()}`}>
          <button
            className={`btn ${this.state.buttonColor}`}
            onClick={(e) => this.addQuestion(e)}
          >{this.getStatus()}</button>
        </div>
      </div>
    );
  }
}

export default AddQuestion
