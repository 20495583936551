import React, { useState } from 'react'

const CompanyListItem = props => {
  // prettier-ignore
  const { isEditing, column, item, index, updateItem, handleDeleteItem, isLink, isCurrentUser } = props
  const [listItemValue, setListItemValue] = useState(item)
  const handleInputChange = e => {
    setListItemValue(e.target.value)
  }
  const handleInputBlur = () => {
    updateItem(index, listItemValue)
  }
  const input = (
    <input
      onBlur={handleInputBlur}
      onChange={handleInputChange}
      type="text"
      name={column}
      value={listItemValue || ''}
    />
  )
  return (
    <>
      {isEditing ? (
        <li className="company-list__item">{input}</li>
      ) : (
        <li className="company-list__item">
          {isLink ? <a href={item}>{item}</a> : item}
          {isCurrentUser && (
            <button
              className="company-list--delete-button"
              onClick={async () => await handleDeleteItem(index)}
            >
              x
            </button>
          )}
        </li>
      )}
    </>
  )
}

export default CompanyListItem
