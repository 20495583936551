/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react";
import Sidebar from "react-sidebar";
import { subscribe } from '../utils/pubSub'

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      subscribeToken: this.subscribeToken(),
    };

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.subscribeToken = this.subscribeToken.bind(this);
  }

  subscribeToken() {
    return subscribe('TOGGLE_SIDE_BAR', () => {
      this.onSetSidebarOpen()
    });
  }

  componentWillUnmount() {
    unsubscribe(this.state.subscribeToken);
  }

  onSetSidebarOpen() {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }

  sideBarContent() {
    const normalizedCompanyUrl = `${this.props.company.id}-${this.props.company.name.replace(/[\. ,:-]+/g, "-")}`
    return (
      <div className="container-fluid">
        <div className="row">
          <nav className="col-md-12 d-none d-md-block sidebar">
            <div className="sidebar-sticky">
              <ul className="nav flex-column">
                <li className="nav-item mt-4">
                  <a
                    className="nav-link active"
                    href={`/companies/${normalizedCompanyUrl}`}
                  >
                    <i className="fa fa-columns mr-2"></i>
                    <span className="">Profile</span>
                  </a>
                </li>
                <li className="nav-item mt-4">
                  <a className="nav-link active" href="/">
                    <i className="fa fa-columns mr-2"></i>
                    <span className="">Dashboard</span>
                  </a>
                </li>
                <li className="nav-item mt-4">
                  <a
                    className="nav-link active"
                    href={this.props.questionnaire_path}
                  >
                    <i className="fa fa-list-ol mr-2"></i>
                    <span className="">Ask Questions</span>
                  </a>
                </li>
                <li className="nav-item mt-4">
                  <a className="nav-link active" href="/answer_questions">
                    <i className="fa fa-tasks mr-2"></i>
                    <span className="">Answer Questions</span>
                  </a>
                </li>
                <li className="nav-item mt-4">
                  <a className="nav-link active" href="/supply_chain_answers">
                    <i className="fa fa-comments mr-2"></i>
                    <span className="">View Supplier Answers</span>
                  </a>
                </li>
                <li className="nav-item mt-4">
                  <a className="nav-link active" href="/supply_chains">
                    <i className="fa fa-sitemap mr-2"></i>
                    <span className="">View your Supply Chain</span>
                  </a>
                </li>

                {this.props.company && (
                  <li className="nav-item mt-4">
                    <a
                      className="nav-link active"
                      href={`/companies/${this.props.company.id}/documents`}
                    >
                      <i className="fa fa-folder mr-2"></i>
                      <span className="">View your Documents</span>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Sidebar
        sidebar={this.sideBarContent()}
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        styles={{
          sidebar: {
            background: "white"
          },
          root: {
            top: '56px',
            position: "fixed",
            zIndex: this.state.sidebarOpen ? 2 : -1 ,
          }
        }}
      >
      <div></div>
      </Sidebar>
    );
  }
}

export default SideBar;
