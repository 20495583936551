import React from 'react'
import ProfileApiHandler from '../profileApiHandler'
import SubmitButton from '../FormComponents/SubmitButton'
import CloseFormButton from '../FormComponents/CloseFormButton'

const KeyContactDeleteForm = props => {
  const { contact, companyId, closeForm, setDoneEditing } = props
  const api = new ProfileApiHandler(companyId)
  const handleDelete = (e, id) => {
    e.preventDefault()
    return api.deleteKeyContact(id).then(() => {
      setDoneEditing(true)
      closeForm()
    })
  }
  return (
    <form
      className="company-profile__form"
      onSubmit={e => handleDelete(e, contact.id)}
    >
      <p>
        Are you sure you want to delete {contact.name || 'this person'} as a key
        contact?
      </p>
      <SubmitButton />
      <CloseFormButton onClick={closeForm} />
    </form>
  )
}

export default KeyContactDeleteForm
