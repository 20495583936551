import { getCSRFToken } from '../../../utils/helpers'

class ProfileApiHandler {
  constructor(id, baseUrl = '/companies') {
    this.id = id
    this.baseUrl = baseUrl
    this.baseUrlWithId = `${baseUrl}/${id}`
    this.headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCSRFToken(document),
      Accept: 'application/json',
    }
  }

  getCompany() {
    return fetch(`${this.baseUrlWithId}/profile/get_company`, {
      method: 'GET',
      headers: this.headers,
    })
  }

  updateCompany(updatedCompany) {
    return fetch(`${this.baseUrlWithId}/profile/update_company`, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify({ company: updatedCompany }),
    })
  }

  updateCompanyArrayFields(updatedFields) {
    return fetch(`${this.baseUrlWithId}/profile/array_fields`, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify({ company: updatedFields }),
    })
  }

  createKeyContact(newContact) {
    return fetch(`${this.baseUrlWithId}/key_contacts`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({ key_contact: newContact }),
    })
  }

  getKeyContacts() {
    return fetch(`${this.baseUrlWithId}/key_contacts`, {
      method: 'GET',
      headers: this.headers,
    })
  }

  editKeyContact(updatedContact, contactId) {
    return fetch(`${this.baseUrlWithId}/key_contacts/${contactId}`, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify({ key_contact: updatedContact }),
    })
  }

  deleteKeyContact(contactId) {
    return fetch(`${this.baseUrlWithId}/key_contacts/${contactId}`, {
      method: 'DELETE',
      headers: this.headers,
    })
  }

  getAnswers() {
    // passed in with /answers/:answer_id
    return fetch(`${this.baseUrlWithId}/answered_questions`, {
      method: 'GET',
      headers: this.headers,
    })
  }

  answerTheQuestion(answer) {
    return fetch(`${this.baseUrl}/profile`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({ answer }),
    })
  }

  editTheAnswer(updatedAnswer) {
    return fetch(`${this.baseUrlWithId}/profile`, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify({
        answer: updatedAnswer,
      }),
    })
  }
}

export default ProfileApiHandler
