import React from 'react'

const SectionWrapper = ({ sectionTitle, children }) => {
  return (
    <section className="company-profile__section">
      <div className="company-profile__section-content">
        <h2>{sectionTitle}</h2>
        {children}
      </div>
    </section>
  )
}

export default SectionWrapper
