/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react"
import PropTypes from "prop-types"
import { subscribe, unsubscribe, publish } from "../../utils/pubSub";

class ViewAnswerQuestionShareButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subscribeToken: this.subscribeToken(),
      count: props.company_questions,
    }

    this.subscribeToken = this.subscribeToken.bind(this);
  }

  componentWillUnmount() {
    unsubscribe(this.state.subscribeToken);
  }

  subscribeToken() {
    return subscribe('UPDATE_QUESTION_COUNT', (eventName, action) => {
      switch (action.type) {
        case 'ADD':
          this.setState({
            count: this.state.count + 1,
          });
          break;
        case 'REMOVE':
          this.setState({
            count: this.state.count - 1,
          });
          break;
      }
    });
  }

  openModal(e) {
    e.preventDefault();

    publish('TOGGLE_QUESTIONS_MODAL', {
      type: 'TOGGLE_MODAL'
    });
  }

  render () {
    return (
      <a
        className="btn btn-primary text-white mb-2"
        onClick={(e) => this.openModal(e)}
      >
        View Your Questions <span className="badge badge-light">{this.state.count}</span>
      </a>
    );
  }
}

export default ViewAnswerQuestionShareButton
