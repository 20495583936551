import React from 'react'
import QuestionAnswerField from '../../FormComponents/QuestionAnswerField'
const questionAttributes = [
  // quick fix for handling dev server being out of sync
  { question: 'Year Founded', id: 449 },
  { question: 'Floor Space', id: 115 },
  { question: 'Employee Count', id: 450 },
  { question: 'Design Capable', id: 451 },
]

const CapabilitiesHeading = props => {
  //prettier-ignore
  const { setDoneEditing, getAnswerAttributes, company, isCurrentUser, env } = props
  const capabilitiesHeading = questionAttributes.map(question => {
    return (
      <div
        key={question.id}
        className="company-profile__section-content capabilities-heading__item"
      >
        <div key={question.id} className="capabilities-heading__item">
          <h4>{question.question}</h4>
          <QuestionAnswerField
            setDoneEditing={setDoneEditing}
            type="text"
            fields={[
              {
                column: 'text',
                tag: 'p',
                placeholder: question.question,
              },
            ]}
            answer={getAnswerAttributes(question.id).text}
            questionId={question.id}
            isCurrentUser={isCurrentUser}
            companyId={company.id}
            answerId={getAnswerAttributes(question.id).id}
          />
        </div>
      </div>
    )
  })
  return (
    <div className="company-profile__capabilities-heading">
      {capabilitiesHeading}
    </div>
  )
}

export default CapabilitiesHeading
