/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from 'react';
import { subscribe, unsubscribe } from '../../utils/pubSub';
import { Button, Modal, } from 'react-bootstrap';

class AnswerQuestionsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      subscribeToken: this.subscribeToken(),
      url: '',
    };

    this.subscribeToken = this.subscribeToken.bind(this);
  }

  subscribeToken() {
    return subscribe('TOGGLE_ANSWER_QUESTIONS_SHARE_MODAL', (eventName, action) => {
      switch (action.type) {
        case 'TOGGLE_MODAL':
          this.setState({
            open: !this.state.open,
            url: action.url,
          });
          break;
      }
    });
  }

  componentWillUnmount() {
    unsubscribe(this.state.subscribeToken);
  }

  getModalClass() {
    return this.state.open ? 'modal fade show' : 'modal';
  }

  closeModal() {
    this.setState({
      open: false,
    });
  }

  copyLink() {
    this.refs.url.select();
    document.execCommand('copy');

    e.target.focus();
  }

  render(){
    return (
      <Modal
        show={this.state.open}
        onHide={() => this.closeModal()}
        dialogClassName="answer-questions-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Questionnaire Share Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-inline">
            <div className="form-group mx-sm-3 mb-2 col-8">
              <label className="sr-only">Password</label>
              <input
                type="text"
                className="form-control col"
                value={this.state.url}
                ref="url"
              />
            </div>
            {
              document.queryCommandSupported('copy') && (
                <button className="btn btn-primary mb-2 col-3" onClick={() => this.copyLink()}>Copy to clipboard</button>
              )
            }
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AnswerQuestionsModal;
