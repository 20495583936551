/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react";
import { publish } from '../utils/pubSub'

class SideBarToggleButton extends React.Component {
  toggleSideBar() {
    publish('TOGGLE_SIDE_BAR');
  }

  render() {
    return (
      <i
        onClick={() => this.toggleSideBar()}
        className="fa fa-bars"
      />
    );
  }
}

export default SideBarToggleButton;
