/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React, { Component } from 'react';
import { getCSRFToken } from '../../../utils/helpers';
import uuid from 'uuid';
import { disableSave } from './utilities';
import CommentsInput from './CommentsInput';

class CheckBoxInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: props.answer ? props.answer.options : [],
      answer: props.answer,
      saving: false,
      currentOptionSaving: '',
    }
  }

  saving() {
    setTimeout(() => {
      this.setState({
        saving: false,
        currentOption: '',
      });
    }, 500);
  }

  async handleOnChange(e) {
    const options = e.target.checked
      ? [...this.state.options, e.target.value]
      : this.state.options.filter(item => item !== e.target.value);

    this.setState({
      saving: true,
      currentOptionSaving: e.target.value,
    })

    disableSave(true);

    if (this.state.answer) {
      try {
        await fetch(`/answers/${this.state.answer.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': getCSRFToken(document),
            'Accept': 'application/json',
          },
          body: JSON.stringify({
            answer: {
              options,
            }
          }),
        }).then(res => res.json())
        .then(answer => {
          this.setState({
            options: answer.options,
          })
        });

        this.saving();
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        await fetch(`/answers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': getCSRFToken(document),
            'Accept': 'application/json',
          },
          body: JSON.stringify({
            answer: {
              options: [...this.state.options, e.target.value],
              question_id: this.props.question.id,
              company_id: this.props.company.id,
            }
          }),
        })
        .then(res => res.json())
        .then(answer => {
          this.setState({
            answer,
            options: answer.options,
          })
        });

        this.saving();
      } catch (err) {
        console.log(err);
      }
    }

    disableSave(false);
  }

  render() {
    const { question, answer } = this.props;

    return (
      <div>
        {
          question.options.map((option, i) => {
            const id = uuid();

            return (
              <div key={id} className="form-check pl-4 pt-2">
                <input
                  className="form-check-input"
                  onChange={(e) => this.handleOnChange(e)}
                  type="checkbox"
                  value={option}
                  checked={ this.state.options.includes(option) }
                />
                <label className="form-check-label" htmlFor={option}>{
                  this.state.saving && this.state.currentOptionSaving === option
                    ? 'Saving...'
                    : option
                }</label>
              </div>
            )
          })
        }

        <div className="form-check pl-4 pt-2">
          <input
            className="form-check-input"
            onChange={(e) => this.handleOnChange(e)}
            type="checkbox"
            value="N/A"
            checked={ this.state.options.includes('N/A') }
          />
          <label className="form-check-label">{
            this.state.saving && this.state.currentOptionSaving === 'N/A'
              ? 'Saving...'
              : 'N/A'
          }</label>
        </div>

        <CommentsInput {...this.props} answer={this.state.answer} />
      </div>
    )
  }
}

export default CheckBoxInput;
