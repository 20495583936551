/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react"
import PropTypes from "prop-types"
import { getCSRFToken } from '../utils/helpers';

// suggestion_options example:
// {
//   controller_name: 'companies',
//   value_key_name: 'id',
//   text_key_name: 'name',
//   form_for: 'address',
//   db_column_name: 'company_id',
//   placeholder: 'Company name'
// }

import Autosuggest from 'react-autosuggest';

class AutoCompleteField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.getValue(props),
      suggestions: [],
      currentSuggestion: this.getCurrentSuggestion(),
    };
  }

  getCurrentSuggestion() {
    if (this.props.field.data) {
      return this.props.field.data;
    }

    if (this.props.data) {
      return this.props.data;
    }

    return {};
  }

  getValue(props) {
    if (typeof props.field.data === 'number' || typeof props.field.data === 'string') {
      return `${props.field.data}`;
    }

    if (props.field_value) {
      return props.field_value;
    }

    return props.field.data ? props.field.data[props.suggestion_options.text_key_name] : '';
  };

  onChange = (event, { newValue }) => {
    if (Object.keys(this.state.currentSuggestion).length > 0) {
      this.setState({
        value: newValue,
        currentSuggestion: {},
      });
    } else {
      this.setState({
        value: newValue,
      });
    }
  };

  renderSuggestion(suggestion) {
    return (
      <div>
        {suggestion[this.props.suggestion_options.text_key_name]}
      </div>
    )
  }

  getSuggestionValue(suggestion) {
    this.setState({
      currentSuggestion: suggestion,
    });

    return suggestion[this.props.suggestion_options.text_key_name];
  }

  onSuggestionsFetchRequested = ({value}) => {
    const query = value.replace(/ /g, '+');

    fetch(`/admin/${this.props.suggestion_options.controller_name}/search?query=${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken(document),
        'Accept': 'application/json',
      },
    })
    .then(res => res.json())
    .then(suggestions => {
      this.setState({
        suggestions,
      });
    })
    .catch((err) => {
      console.log('error: ', err);
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  renderInput(inputProps) {
    return (
      <div>
        <input {...inputProps} />
        <input
          style={{ display: 'none' }}
          name={`${this.props.suggestion_options.form_for}[${this.props.suggestion_options.db_column_name}]`}
          type="text"
          value={this.state.currentSuggestion.id || ''}
          onChange={() => {}}
        />
      </div>
    );
  }

  render() {
    const { value, suggestions } = this.state;
    const { created_at, updated_at, ...currentSuggestion } = this.state.currentSuggestion;
    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: this.props.suggestion_options.placeholder,
      value,
      onChange: this.onChange
    };

    // Finally, render it!
    return (
      <>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={(suggestion) => this.onSuggestionsFetchRequested(suggestion)}
        onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
        getSuggestionValue={(suggestion) => this.getSuggestionValue(suggestion)}
        renderSuggestion={(suggestion) => this.renderSuggestion(suggestion)}
        inputProps={inputProps}
        renderInputComponent={(input) => this.renderInput(input)}
      />

      {
        Object.keys(currentSuggestion).length > 0 && (
          <ul className="autosuggest__preview">
          {Object.keys(currentSuggestion).map((key, i) => {
            let value = this.state.currentSuggestion[key];

            if (Array.isArray(this.state.currentSuggestion[key])) {
              value = this.state.currentSuggestion[key].join(', ');
            }

            return (
              <li key={`${key}-${i}`}>{key}: {value}</li>
            )
          })}
          </ul>
        )
      }
      </>
    );
  }
}

export default AutoCompleteField
