import React from 'react'

import QuestionAnswerField from '../FormComponents/QuestionAnswerField'
import SectionWrapper from './SectionWrapper'
const ProductsAndServices = props => {
  const { setDoneEditing, text, companyId, answerId, isCurrentUser } = props
  return (
    <SectionWrapper sectionTitle="Products and Services">
      <QuestionAnswerField
        setDoneEditing={setDoneEditing}
        type="textarea"
        fields={[
          {
            column: 'text',
            tag: 'p',
            placeholder:
              'Tell your customers about your products and services.',
          },
        ]}
        questionId={12}
        companyId={companyId}
        isCurrentUser={isCurrentUser}
        answerId={answerId}
        answer={text}
      />
    </SectionWrapper>
  )
}

export default ProductsAndServices
