/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react"
import PropTypes from "prop-types"
import { publish } from '../../utils/pubSub'
class ViewAnswerQuestionsShareButton extends React.Component {
  openModal(e) {
    e.preventDefault();

    publish('TOGGLE_ANSWER_QUESTIONS_SHARE_MODAL', {
      type: 'TOGGLE_MODAL',
      url: this.props.url,
    });
  }

  render () {
    return (
      <a
        className="btn btn-primary text-white mb-2"
        onClick={(e) => this.openModal(e)}
      >
        Share Link
      </a>
    );
  }
}

export default ViewAnswerQuestionsShareButton
