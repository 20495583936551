/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react"
import Spinner from 'react-bootstrap/Spinner'
import PropTypes from "prop-types"
import { publish } from '../../utils/pubSub';
import { getCSRFToken } from '../../utils/helpers';
import { ThemeProvider } from 'react-bootstrap';

class QuestionnaireQuestions extends React.Component {
  constructor(props) {
    super(props);

    const hasQuestion = props.questionnaire_questions.some(
      question => question.id === props.question.id
    );

    this.state = {
      background: '',
      buttonColor: 'btn-danger',
      display: true,
      orderNumber: this.props.index + 1,
      order: props.questionsOrder,
      loading: false,
      deleting: false,
    }
    this.handleOnOrderChange = this.handleOnOrderChange.bind(this);
  }

  removeQuestion(e) {
    e.preventDefault();
    this.setState({ deleting: true });
    document.body.style.pointerEvents = 'none';

    fetch(`/questionnaires_questions/delete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken(document),
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        questionnaires_question: {
          question_id: this.props.question.id,
          questionnaire_id: this.props.questionnaire.id,
        }
      }),
    })
    .then(res => res.json())
    .then(question => {
      this.setState({ order: this.state.order.filter(item => item !== this.props.question.id)});
      location.reload();
    })
    .catch((err) => {
      console.log('error: ', err);
    });
  }

  getBackgroundColor() {
    if (this.state.background) {
      return this.state.background
    }

    if (this.props.index % 2 === 0) {
      return 'bg-light';
    }

    return 'bg-white';
  }

  getReorderedOrder (index) {
    const dupOrder = [...this.props.questionsOrder];
    const currentIndex = this.props.questionsOrder.indexOf(this.props.question.id);
    dupOrder.splice(currentIndex, 1);
    dupOrder.splice(index, 0, this.props.question.id);
    
    return dupOrder;
  }
 
  async handleOnOrderChange (e) {
    const orderNumber = e.target.value;
    const index = parseInt(e.target.value) - 1;
    document.body.style.pointerEvents = 'none';

    this.setState({ loading: true });

    try {
      await fetch(`/questionnaires/${this.props.questionnaire.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCSRFToken(document),
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          questionnaire: {
            order: this.getReorderedOrder(index),
          },
        }),
      }).then((res) => res.json());
      this.setState({ orderNumber });
      location.reload();
    } catch (err) {
      console.log(err);
    }
  }

  getQuestionOrder () {
    const order = [];
    for (let i = 0; i < this.props.orderOptions; i++) {
      order.push(i + 1);
    }

    return order;
  }

  render () {
    if (this.state.display) {
      return (
        <div className="row col">
          <div className={`col-1 border p-2 ${this.getBackgroundColor()} center`}>
            {
              this.state.loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <select
                  value={this.state.orderNumber}
                  style={{ width: '100%' }}
                  onChange={this.handleOnOrderChange}
                >
                  <option value=""></option>
                  {
                    this.getQuestionOrder().map(option => {
                      return (
                        <option key={option} value={option}>{option}</option>
                      );
                    })
                  }
                </select>
              )
            }
          </div>
          <div className={`col-2 border p-2 ${this.getBackgroundColor()}`}>{`${this.props.question_percent}%`}</div>
          <div className={`col-5 border p-2 ${this.getBackgroundColor()}`}>{this.props.question.text}</div>
          <div className={`col-2 border p-2 ${this.getBackgroundColor()}`}>{this.props.category ? this.props.category.name : ''}</div>
          <div className={`col-2 border p-2 ${this.getBackgroundColor()}`}>
            <button
              className={`btn ${this.state.buttonColor}`}
              onClick={(e) => this.removeQuestion(e)}
            >{this.state.deleting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                ) : (
                  'Remove'
                )
              }</button>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default QuestionnaireQuestions;
