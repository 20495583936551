/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react"
import PropTypes from "prop-types"

import InputOptions from './InputOptions';
import { getCSRFToken } from '../../utils/helpers';

class NewQuestion extends React.Component {
  constructor() {
    super();

    this.state = {
      input_type: 'text',
      options: [],
      graph_type: '',
      current_option: '',
      text: '',
      saving: false,
    }
  }

  handleInputType(e) {
    this.setState({
      input_type: e.target.value,
    });
  }

  addOption(e) {
    e.preventDefault();

    this.setState({
      options: [...this.state.options, this.state.current_option],
      current_option: '',
    })
  }

  updateCurrentOption(e) {
    this.setState({
      current_option: e.target.value,
    })
  }

  saving() {
    setTimeout(() => {
      this.setState({
        saving: false,
      });
    }, 500);
  }

  removeInputOption(index) {
    this.setState({
      options: this.state.options.filter((option, i) => i !== index),
    })
  }

  renderInputArea() {
    const optionsInput = ['select', 'checkbox', 'radio']
    if (optionsInput.includes(this.state.input_type)) {
      return (
        <div>
          <div className="form-group">
            <label>Input Options</label>
              <ul>
              {
                this.state.options.map((option, i) => {
                  return (
                    <li
                      key={`${option}-${i}`}
                      style={{
                        listStyleType: 'none',
                      }}
                    >
                      <i
                        onClick={() => this.removeInputOption(i)}
                        className="fa fa-times-circle"
                        style={{
                          cursor: 'pointer',
                          marginRight: '5px',
                        }}
                      ></i>
                        {option}
                    </li>
                  )
                })
              }
              </ul>
          </div>
          <div className="form-group">
            <InputOptions
              addOption={(e) => this.addOption(e)}
              updateCurrentOption={(e) => this.updateCurrentOption(e)}
              currentOption={this.state.current_option}
            />
          </div>
        </div>
      )
    }

    return null;
  }

  handleQuestionChange(e) {
    this.setState({
      text: e.target.value,
    })
  }

  graphOnChange(e) {
    this.setState({
      graph_type: e.target.value,
    })
  }

  async submitNewQuestion(e) {
    e.preventDefault();

    this.setState({
      saving: true,
    })

    try {
      await fetch('/questionnaires_questions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCSRFToken(document),
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          questionnaires_question: {
            questionnaire_id: this.props.questionnaire_id,
          },
          question: {
            ...this.state,
          },
        }),
      })
      .then(res => res.json())
      .then(question => {
        location.href = question.url;
      });

      this.saving();
    } catch (err) {
      console.log('something went wrong');
    }
  }

  render () {
    return (
      <div className="card card-form">
        <div className="col-lg-4 card-body">
          <p><strong className="headings-color">Create a new question</strong></p>
        </div>
        <div className="col-lg-8 card-form__body card-body">
          <form>
            <div className="form-group">
              <label>Question</label>
              <input
                onChange={(e) => this.handleQuestionChange(e)}
                value={this.state.text}
                type="text"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="questionInputType">Input Type</label>
              <select
                onChange={(e) => this.handleInputType(e)}
                className="form-control"
                id="questionInputType"
                value={this.state.input_type}
              >
                <option value="text">Text</option>
                <option value="textarea">Text Area</option>
                <option value="select">Select Box</option>
                <option value="radio">Radio Button</option>
                <option value="checkbox">Check Box</option>
                <option value="number">Number</option>
                <option value="date">Date</option>
              </select>
            </div>

            { this.renderInputArea() }
            <button
              onClick={(e) => this.submitNewQuestion(e)}
              type="submit"
              className="btn btn-primary"
            >{this.state.saving ? 'Submitting...' : 'Submit'}</button>
          </form>
        </div>
      </div>
    );
  }
}

export default NewQuestion
