import React from 'react'

import QuestionAnswerField from '../FormComponents/QuestionAnswerField'
import SectionWrapper from './SectionWrapper'
const CovidResponse = props => {
  const { setDoneEditing, text, companyId, answerId, isCurrentUser } = props
  return (
    <SectionWrapper sectionTitle="COVID-19 Response">
      <QuestionAnswerField
        setDoneEditing={setDoneEditing}
        type="textarea"
        fields={[
          {
            column: 'text',
            tag: 'p',
            placeholder:
              'Please provide a general description of how you are handling the Covid-19 pandemic',
          },
        ]}
        questionId={381}
        companyId={companyId}
        isCurrentUser={isCurrentUser}
        answerId={answerId}
        answer={text}
      />
    </SectionWrapper>
  )
}

export default CovidResponse
