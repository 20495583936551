/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React, { Component } from 'react';
import { getCSRFToken } from '../../../utils/helpers';
import { disableSave } from './utilities';
import CommentsInput from './CommentsInput';

class NumberInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: props.answer ? props.answer.text : '',
      saving: false,
      answer: props.answer || '',
    }
  }

  saving() {
    setTimeout(() => {
      this.setState({
        saving: false,
      });
    }, 500);
  }

  handleOnChange(e) {
    e.preventDefault();

    this.setState({
      input: e.target.value,
    })
  }

  async submitAnswer(e) {
    e.preventDefault();

    this.setState({
      saving: true,
    })

    disableSave(true);

    if (this.state.answer) {
      try {
        await fetch(`/answers/${this.state.answer.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': getCSRFToken(document),
            'Accept': 'application/json',
          },
          body: JSON.stringify({
            answer: {
              text: e.target.value,
            }
          }),
        })
        .then(res => res.json())
        .then(answer => {
          this.setState({
            answer,
            input: answer.text,
          })
        });

        this.saving();
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        await fetch(`/answers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': getCSRFToken(document),
            'Accept': 'application/json',
          },
          body: JSON.stringify({
            answer: {
              text: e.target.value,
              question_id: this.props.question.id,
              company_id: this.props.company.id,
            }
          }),
        })
        .then(res => res.json())
        .then(answer => {
          this.setState({
            answer,
            input: answer.text,
          })
        });

        this.saving();
      } catch (err) {
        console.log(err);
      }
    }

    disableSave(false);
  }

  render() {
    const { question } = this.props;

    return (
      <form>
        <div className="form-group text-center">
          {
            this.state.saving ? (
              <input
                type='text'
                className="form-control"
                value="Saving..."
              />
            ) : (
              <input
                type='number'
                className="form-control"
                id={`question-${question.id}`}
                value={this.state.input}
                onBlur={(e) => this.submitAnswer(e)}
                onChange={(e) => this.handleOnChange(e)}
              />
            )
          }
        </div>

        <CommentsInput {...this.props} answer={this.state.answer} />
      </form>
    )
  }
}

export default NumberInput;
