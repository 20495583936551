/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React, { Component } from 'react';
import { getCSRFToken } from '../../../utils/helpers';
import { disableSave } from './utilities';
import CommentsInput from './CommentsInput';

class SelectInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: props.answer ? props.answer.options : [],
      saving: false,
    }
  }

  saving() {
    setTimeout(() => {
      this.setState({
        saving: false,
      });
    }, 500);
  }

  async handleOnChange(e) {
    e.preventDefault();

    this.setState({
      saving: true,
    })

    disableSave(true);

    if (this.props.answer) {
      try {
        await fetch(`/answers/${this.props.answer.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': getCSRFToken(document),
            'Accept': 'application/json',
          },
          body: JSON.stringify({
            answer: {
              options: [e.target.value],
            }
          }),
        }).then(res => res.json())
        .then(answer => {
          this.setState({
            options: answer.options,
          })
        });

        this.saving();
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        await fetch(`/answers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': getCSRFToken(document),
            'Accept': 'application/json',
          },
          body: JSON.stringify({
            answer: {
              options: [e.target.value],
              question_id: this.props.question.id,
              company_id: this.props.company.id,
            }
          }),
        })
        .then(res => res.json())
        .then(answer => {
          this.setState({
            options: answer.options,
          })
        });

        this.saving();
      } catch (err) {
        console.log(err);
      }
    }

    disableSave(false);
  }

  render() {
    const { question } = this.props;

    return (
      <form>
        {
          this.state.saving ? (
            <div>Saving...</div>
          ) : (
            <div className="input-group mb-3">
              <select
                className="custom-select"
                onChange={ (e) => this.handleOnChange(e) }
                value={ this.state.options[0] }
              >
                <option value=""></option>
                {
                  question.options.map((option, i) => {
                    return (
                      <option
                        key={`${option}-${i}`}
                        value={ option }
                      >{ option }</option>
                    )
                  })
                }
                <option value="N/A">N/A</option>
              </select>
            </div>
          )
        }

        <CommentsInput {...this.props} answer={this.state.answer} />
      </form>
    )
  }
}

export default SelectInput;
