/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react"
import PropTypes from "prop-types"
import { publish } from '../../utils/pubSub';
import { getCSRFToken } from '../../utils/helpers';
import { Form, InputGroup, Button, Col, Container } from 'react-bootstrap';

class NewSupplier extends React.Component {
  state = {
    validated: false,
    visible: false,
    name: '',
    city: '',
    state: '',
    contact_name: '',
    email: '',
    phone_number: '',
  }

  handleOnChange(e) {
    this.setState({
      name: this.refs.name.value,
      city: this.refs.city.value,
      state: this.refs.state.value,
      contact_name: this.refs.contact_name.value,
      email: this.refs.email.value,
      phone_number: this.refs.phone_number.value,
    })
  }

  addSupplier(e) {
    e.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() !== false) {
      fetch(`/supply_chains`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCSRFToken(document),
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          supply_chain: {
            company_id: this.props.company.id,
            company: {
              name: this.state.name,
            },
            address: {
              city: this.state.city,
              state: this.state.state,
              phone_number_1: this.state.phone_number,
            },
            invite: {
              name: this.state.name,
              city: this.state.city,
              state: this.state.state,
              contact_name: this.state.contact_name,
              email: this.state.email,
              phone_number: this.state.phone_number,
              company_id: this.props.company.id,
              supply_chain_type: this.props.supply_chain_type,
            }
          }
        }),
      })
      .then(res => res.json())
      .then(supplier => {
        location.href = supplier.url;
      })
      .catch((err) => {
        console.log('error: ', err);
      });
    }

    this.setState({
      validated: true,
    });
  }

  render() {
    if (!this.state.visible) {
      return (
        <div className="row col mt-4 mb-4">
          <button
            className="btn btn-primary"
            onClick={() => this.setState({visible: true})}
          >Invite New {this.props.supply_chain_type}</button>
        </div>
      )
    }

    return (
      <>
        <h3 className="mt-4">Invite New {this.props.supply_chain_type}</h3>

        <Container className="mt-4 mb-4 border">
          <Form
            className="mt-4 mb-4"
            noValidate
            validated={this.state.validated}
            onSubmit={(e) => this.addSupplier(e)}
          >
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Company Name (*required)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Toyota"
                  value={this.state.name}
                  ref="name"
                  onChange={(e) => this.handleOnChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Company name is required.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Contact Name (*required)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="John Doe"
                  value={this.state.contact_name}
                  ref="contact_name"
                  onChange={(e) => this.handleOnChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Contact name is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Email (*required)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="johndoe@toyota.com"
                  value={this.state.email}
                  ref="email"
                  onChange={(e) => this.handleOnChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Email is required
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="4">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="555-555-5555"
                  value={this.state.phone_number}
                  ref="phone_number"
                  onChange={(e) => this.handleOnChange(e)}
                />
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="City"
                  value={this.state.city}
                  ref="city"
                  onChange={(e) => this.handleOnChange(e)}
                />
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="State"
                  value={this.state.state}
                  ref="state"
                  onChange={(e) => this.handleOnChange(e)}
                />
              </Form.Group>
            </Form.Row>
            <Button type="submit">Invite New {this.props.supply_chain_type}</Button>
          </Form>
        </Container>
      </>
    )
  }
}

export default NewSupplier
