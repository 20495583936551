import React from 'react'
import EditableInput from './EditableInput'

const CompanyProfileSection = props => {
  return (
    <section>
      <EditableInput
        editValue={props.renderedData.company.name}
        renderDataPropertyName="company"
        propertyName="name"
        requestId={props.renderedData.company.id}
        renderedData={props.renderedData}
        setRenderedData={props.setRenderedData}
        isCurrentUser={props.isCurrentUser}
      >
        <h1>{props.renderedData.company.name}</h1>
      </EditableInput>

      <EditableInput
        editValue={props.renderedData.company.headline}
        renderDataPropertyName="company"
        propertyName="headline"
        requestId={props.renderedData.company.id}
        renderedData={props.renderedData}
        setRenderedData={props.setRenderedData}
        inputType="textarea"
        label="Headline"
        isCurrentUser={props.isCurrentUser}
      >
        <div>{props.renderedData.company.headline}</div>
      </EditableInput>
    </section>
  )
}

export default CompanyProfileSection
