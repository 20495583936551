import React, { useState } from 'react'
import KeyContactForm from './KeyContactForm'
import EditButton from '../FormComponents/EditButton'
import KeyContactDeleteForm from './KeyContactDeleteForm'
import ProfileApiHandler from '../profileApiHandler'
import DeleteButton from '../FormComponents/DeleteButton'

const KeyContact = ({ contact, companyId, setDoneEditing, isCurrentUser }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [renderedContact, setRenderedContact] = useState(contact)
  const api = new ProfileApiHandler(companyId)
  let formToRender
  if (isEditing) {
    formToRender = (
      <KeyContactForm
        editing
        contact={renderedContact}
        setContact={setRenderedContact}
        setDoneEditing={setDoneEditing}
        companyId={companyId}
        closeEdit={() => setIsEditing(false)}
        apiAction={(updatedContact, contactId) =>
          api.editKeyContact(updatedContact, contactId)
        }
      />
    )
  } else if (isDeleting) {
    formToRender = (
      <KeyContactDeleteForm
        companyId={companyId}
        contact={contact}
        setDoneEditing={setDoneEditing}
        closeForm={() => setIsDeleting(false)}
      />
    )
  }
  const handleClick = setIsState => {
    setDoneEditing(false)
    setIsState(true)
  }

  const editDeleteButtons = isCurrentUser && (
    <>
      <EditButton onClick={() => handleClick(setIsEditing)} />
      <DeleteButton onClick={() => handleClick(setIsDeleting)} />
    </>
  )
  return (
    <figure className="key-contact-card">
      {!isEditing && !isDeleting ? (
        <>
          <h4>{contact.job_title}</h4>
          <p className="key-contact-card__contact">{contact.email}</p>
          <p className="key-contact-card__contact">{contact.phone}</p>
          <p className="key-contact-card__contact">{contact.name}</p>
          {editDeleteButtons}
        </>
      ) : (
        formToRender
      )}
    </figure>
  )
}

export default KeyContact
