import React from 'react'

import SectionWrapper from '../SectionWrapper'
import CapabilitiesHeading from './CapabilitiesHeading'
import CompanyListItems from '../../CompanyListItems'

const CapabilitiesSection = props => {
  //prettier-ignore
  const { company, isCurrentUser, setDoneEditing, getAnswerAttributes, env } = props
  return (
    <SectionWrapper sectionTitle="Capabilities">
      <CapabilitiesHeading
        setDoneEditing={setDoneEditing}
        getAnswerAttributes={getAnswerAttributes}
        company={company}
        isCurrentUser={isCurrentUser}
        env={env}
      />
      <CompanyListItems
        items={company.equipment}
        companyId={company.id}
        column="equipment"
        sectionTitle="Equipment"
        isCurrentUser={isCurrentUser}
      />

      <CompanyListItems
        items={company.services}
        companyId={company.id}
        column="services"
        sectionTitle="Services"
        isCurrentUser={isCurrentUser}
      />

      <CompanyListItems
        items={company.links}
        companyId={company.id}
        column="links"
        sectionTitle="Links"
        isLink
        isCurrentUser={isCurrentUser}
      />
    </SectionWrapper>
  )
}

export default CapabilitiesSection
