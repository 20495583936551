import React, { useState, useEffect } from 'react'

import EditButton from '../FormComponents/EditButton'
import ProfileApiHandler from '../profileApiHandler'

const EditableInput = props => {
  const api = new ProfileApiHandler(props.requestId)
  const [value, setValue] = useState(props.editValue)
  const [edit, setEdit] = useState(false)
  useEffect(() => {
    //prettier-ignore
    const hasEmptyField = !props.renderedData[props.renderDataPropertyName][props.propertyName]
    if (hasEmptyField) {
      setEdit(true)
    }
  }, [])
  return (
    <div>
      {props.label && (
        <div style={{ width: '100%' }}>
          <label>{props.label}</label>
        </div>
      )}

      <div style={{ display: 'flex' }}>
        {edit && props.isCurrentUser ? (
          <div
            className="form-group text-center"
            style={{ marginRight: '20px' }}
          >
            {props.inputType === 'textarea' ? (
              <textarea
                value={value}
                rows="8"
                cols="80"
                className="form-control"
                onChange={e => setValue(e.target.value)}
              />
            ) : (
              <input
                type="text"
                style={{ fontSize: '2.5rem' }}
                className="form-control"
                value={value}
                onChange={e => setValue(e.target.value)}
              />
            )}
          </div>
        ) : (
          <div style={{ marginRight: '20px' }}>{props.children}</div>
        )}

        {edit && props.isCurrentUser ? (
          <div>
            <i
              className="fa fa-check-circle-o"
              aria-hidden="true"
              onClick={() => {
                api
                  .updateCompany({
                    [props.propertyName]: value,
                  })
                  .then(res => res.json())
                  .then(data => {
                    props.setRenderedData({ ...props.renderedData, ...data })
                    setEdit(false)
                  })
              }}
            />
            <i
              className="fa fa-times-circle"
              aria-hidden="true"
              onClick={() => setEdit(false)}
            />
          </div>
        ) : (
          props.isCurrentUser && (
            <EditButton onClick={() => setEdit(!edit)} disabled={false} />
          )
        )}
      </div>
    </div>
  )
}

export default EditableInput
