import React from 'react'

import SectionWrapper from './SectionWrapper'
import KeyContacts from '../KeyContacts'
const KeyContactsSection = ({ keyContacts, companyId, isCurrentUser }) => {
  return (
    <SectionWrapper sectionTitle="Key Contacts">
      <KeyContacts
        contacts={keyContacts}
        companyId={companyId}
        isCurrentUser={isCurrentUser}
      />
    </SectionWrapper>
  )
}

export default KeyContactsSection
