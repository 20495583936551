import React, { useState, useEffect } from 'react'

import ProfileApiHandler from '../profileApiHandler'

import EditButton from './EditButton'
import SubmitButton from './SubmitButton'
import CloseFormButton from './CloseFormButton'

const handleQuestionAnswer = (form, ids) => {
  const answerApiHandler = new ProfileApiHandler(ids.answerId, '/answers')
  const newAnswer = {
    ...form,
    company_id: ids.companyId,
    question_id: ids.questionId,
  }
  return async answerId =>
    answerId
      ? answerApiHandler.editTheAnswer(newAnswer)
      : answerApiHandler.answerTheQuestion(newAnswer)
}

const submitForm = (e, form, ids, funcs) => {
  e.preventDefault()
  const { setAnswerText, setEditing } = funcs
  if (!Object.keys(form).length) {
    setEditing(false)
    return false
  }

  return answerId => {
    const submitterInit = handleQuestionAnswer(form, ids)
    return submitterInit(answerId).then(async res => {
      const { answer } = await res.json()
      setAnswerText(answer.text)
      setEditing(false)
    })
  }
}

const QuestionAnswerField = props => {
  const {
    fields,
    companyId,
    questionId,
    answerId,
    type,
    isCurrentUser,
    answer,
  } = props
  const [editing, setEditing] = useState(false)
  const [form, setForm] = useState({})
  const [answerText, setAnswerText] = useState(answer)
  useEffect(() => {
    if (!answerText) {
      setEditing(true)
    }
  }, [])
  const handleInputChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }
  const editableInputs = fields.map((field, i) =>
    type === 'textarea' ? (
      <textarea
        className="company-profile__textarea"
        key={field.column}
        type="text"
        value={form[field.column] || ''}
        name={field.column}
        placeholder={field.placeholder}
        onChange={e => handleInputChange(e)}
      />
    ) : (
      <input
        className="company-profile__form-input"
        key={field.column}
        type="text"
        value={form[field.column] || ''}
        name={field.column}
        placeholder={field.placeholder}
        onChange={e => handleInputChange(e)}
      />
    )
  )
  const ids = { companyId, questionId, answerId }
  const submitFuncs = { setEditing, setAnswerText }
  const editForm = (
    <form
      className="company-profile__form static"
      onSubmit={e => {
        const submitInit = submitForm(e, form, ids, submitFuncs)
        return submitInit && submitInit(answerId)
      }}
    >
      {editableInputs}
      <SubmitButton />
      <CloseFormButton onClick={() => setEditing(false)} />
    </form>
  )
  const renderedFields = fields.map(field => {
    const Tag = field.tag
    console.log(answerText)
    return <Tag key={field.column}>{answerText}</Tag>
  })

  const renderedSection = (
    <div>
      {renderedFields}
      {isCurrentUser && (
        <EditButton
          className="company-profile__btn--edit"
          onClick={() => {
            setEditing(true)
          }}
        />
      )}
    </div>
  )
  return <div>{editing && isCurrentUser ? editForm : renderedSection}</div>
}

export default QuestionAnswerField
