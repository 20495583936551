import React, { useEffect, useState } from 'react'

import ProfileApiHandler from './profileApiHandler'
import AnsweredQuestion from './AnsweredQuestion'
import ProductsAndServices from './Sections/ProductsAndServices'
import KeyContactsSection from './Sections/KeyContactsSection'
import CapabilitiesSection from './Sections/CapabilitiesSection'
import SystemAndCertsSection from './Sections/SystemsAndCertsSection'
import SectionWrapper from './Sections/SectionWrapper'
import CovidResponse from './Sections/CovidResponse'

const CompanyProfile = props => {
  const { company, keyContacts, answers, isCurrentUser, env } = props
  const companyApi = new ProfileApiHandler(company.id)
  const answersApi = new ProfileApiHandler(company.id, '/answers')
  const [doneEditing, setDoneEditing] = useState(false)
  const [renderedData, setRenderedData] = useState({ company, answers })
  useEffect(() => {
    const fetchRenderedData = async () => {
      const companyCall = companyApi.getCompany()
      const answerCall = answersApi.getAnswers()
      const promises = [companyCall, answerCall]
      await Promise.all(promises)
        .then(async ([companyRes, answerRes]) => {
          const { answers } = await answerRes.json()
          const { company } = await companyRes.json()
          return { answers, company }
        })
        .then(data => setRenderedData(data))
    }
    fetchRenderedData()
  }, [doneEditing])
  const findAnswer = questionId =>
    renderedData.answers.find(answer => answer.question_id === questionId)

  const getAnswerAttributes = questionId => {
    const foundAnswer = findAnswer(questionId)
    if (foundAnswer) {
      return { text: foundAnswer.text, id: foundAnswer.id }
    }
    return { text: '', id: null }
  }

  return (
    <main className="company-profile">
      <SectionWrapper sectionTitle={company.name}>
        <AnsweredQuestion data={getAnswerAttributes(111).text} />
      </SectionWrapper>

      <ProductsAndServices
        setDoneEditing={setDoneEditing}
        companyId={company.id}
        isCurrentUser={isCurrentUser}
        answerId={getAnswerAttributes(12).id}
        text={getAnswerAttributes(12).text}
      />

      <CovidResponse
        setDoneEditing={setDoneEditing}
        companyId={company.id}
        isCurrentUser={isCurrentUser}
        answerId={getAnswerAttributes(381).id}
        text={getAnswerAttributes(381).text}
      />

      <KeyContactsSection
        keyContacts={keyContacts}
        companyId={company.id}
        isCurrentUser={isCurrentUser}
      />

      <CapabilitiesSection
        company={company}
        isCurrentUser={isCurrentUser}
        setDoneEditing={setDoneEditing}
        getAnswerAttributes={getAnswerAttributes}
        env={env}
      />

      <SystemAndCertsSection company={company} isCurrentUser={isCurrentUser} />
    </main>
  )
}

export default CompanyProfile
