/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from 'react';

const InputOptions = (props) => {
  return (
    <React.Fragment>
      <input onChange={props.updateCurrentOption} type="text" value={props.currentOption} className="form-control" />

      <div className="text-right">
        <button onClick={props.addOption} className="btn btn-primary mt-2">Add Option</button>
      </div>
    </React.Fragment>
  )
}

export default InputOptions;
