/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from "react"
import PropTypes from "prop-types"
import { publish } from '../../utils/pubSub';
import { getCSRFToken } from '../../utils/helpers';

class AddSupplier extends React.Component {
  getPayload() {
    if (this.props.supply_chain_type === 'supplier') {
      return {
        supply_chain: {
          company_id: this.props.company.id,
          supplier_id: this.props.supplier.id,
        }
      }
    }

    if (this.props.supply_chain_type === 'customer') {
      return {
        supply_chain: {
          company_id: this.props.supplier.id,
          supplier_id: this.props.company.id,
          verified_by_supplier: true,
        }
      }
    }

    return {};
  }

  addSupplier(e) {
    e.preventDefault();

    fetch(`/supply_chains`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken(document),
        'Accept': 'application/json',
      },
      body: JSON.stringify(this.getPayload()),
    })
    .then(res => res.json())
    .then(supplier => {
      location.href = supplier.url;
    })
    .catch((err) => {
      console.log('error: ', err);
    });
  }

  getIsConnected() {
    if (this.props.is_customer) {
      return <div>Already a Customer</div>
    }

    if (this.props.is_supplier) {
      return <div>Already a Supplier</div>
    }

    return (
      <button
        className="btn btn-primary"
        onClick={(e) => this.addSupplier(e)}
      >Add to Supply Chain</button>
    );
  }

  render () {
    const { company, supplier, is_supplier, address } = this.props;

    return (
      <div className="row col">
        <div className="col-3 border p-2">{ supplier.name }</div>
        <div className="col-3 border p-2">{ address.city }</div>
        <div className="col-3 border p-2">{ address.state }</div>
        <div className="col-3 border p-2">
          {this.getIsConnected()}
        </div>
      </div>
    );
  }
}

export default AddSupplier
