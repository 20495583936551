/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

function csrfToken(document) {
  return document.querySelector('[name="csrf-token"]').content;
}

export function getCSRFToken(document) {
  return csrfToken(document);
}

export const graphColors = [
  '#88d8b0',
  '#ffb554',
  '#ff6f69',
  '#c0ed84',
  '#74cba7',
  '#729ea1',
  '#ffcc5c',
  '#f6db5f',
  '#96ceb4',
  '#ffeead',
  '#9e3d64',
  '#fe5e51',
  '#36abb5',
  '#a1cddf',
  '#c0ed84',
  '#74cba7',
  '#729ea1',
  '#92de99',
  '#bad8e1',
  '#92b4c9',
  '#628da7',
  '#447c92',
  '#035e83',
]
