import React from 'react'

const CloseFormButton = ({ onClick }) => {
  return (
    <i
      className="fa fa-times-circle company-profile__button--close"
      onClick={onClick}
      aria-hidden="true"
    />
  )
}

export default CloseFormButton
