/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import PubSub from 'pubsub-js';

export const subscribe = (eventName, callback) => {
  return PubSub.subscribe(eventName, callback);
};

export const publish = (eventName, data) => {
  PubSub.publish(eventName, data);
};

export const unsubscribe = (token) => {
  PubSub.unsubscribe(token);
};
