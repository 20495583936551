/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from 'react';
import { subscribe, unsubscribe } from '../../utils/pubSub';
import { getCSRFToken } from '../../utils/helpers';
import { Button, Modal, } from 'react-bootstrap';
import AddQuestion from './AddQuestion';

class AnswerQuestionsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      subscribeToken: this.subscribeToken(),
      questions: [],
    };

    this.subscribeToken = this.subscribeToken.bind(this);
  }

  componentDidMount() {
    try {
      fetch(`/questionnaires/${this.props.questionnaire.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCSRFToken(document),
          'Accept': 'application/json',
        },
      })
      .then(res => res.json())
      .then(data => {
        this.setState({
          questions: data.questions,
        })
      })
    } catch (err) {
      console.log(err);
    }
  }

  subscribeToken() {
    return subscribe('TOGGLE_QUESTIONS_MODAL', (eventName, action) => {
      switch (action.type) {
        case 'TOGGLE_MODAL':
          this.setState({
            open: !this.state.open,
          });
          break;
        case 'ADD':
          this.setState({
            questions: [...this.state.questions, action.question],
          });
          break;
        case 'REMOVE':
          this.setState({
            questions: this.state.questions.filter(question => question.id !== action.question.id)
          });
          break;
      }
    });
  }

  componentWillUnmount() {
    unsubscribe(this.state.subscribeToken);
  }

  getModalClass() {
    return this.state.open ? 'modal fade show' : 'modal';
  }

  closeModal() {
    this.setState({
      open: false,
    });
  }

  render(){
    return (
      <Modal
        show={this.state.open}
        onHide={() => this.closeModal()}
        dialogClassName="questions-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Questions for your supplier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container font-weight-bold">
            <div className="row col pt-2 pb-2">
              <div className="col-2 border">% of Companies Who Have Answered</div>
              <div className="col-6 border">Question</div>
              <div className="col-2 border">Category</div>
              <div className="col-2 border">Add/Remove</div>
            </div>
          </div>
          <div className="container">
            {
              this.state.questions.map((question, i) => {
                return (
                  <AddQuestion
                    key={question.id}
                    question={question}
                    question_percent={question.question_percent}
                    index={i}
                    category={question.category}
                    questionnaire_questions={this.state.questions}
                    questionnaire={this.props.questionnaire}
                  />
                )
              })
            }
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AnswerQuestionsModal;
