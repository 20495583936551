/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

export const disableSave = (disable) => {
  const saveButton = document.getElementById('save-button');

  if (disable) {
    saveButton.classList.add('disabled');
  } else {
    saveButton.classList.remove('disabled');
  }
}